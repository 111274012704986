import React from 'react'
import Layout from '../layouts/layout'
import { section, btnOutline, bgColor } from '../styles/styles'

import Seo from '../components/seo'
import reglement from '../../static/reglement-tafraout-camp-2023.pdf'

const Reglement = () => {
    return (
        <Layout>
            <section className={`${section}  bg-[#0c1934] pt-4 sm:pt-6 `}>
                <div className="wrapper-lg px-0 sm:px-4 lg:px-6">
                <div className='relative mb-8'>
                    <h1 className="title relative z-10 text-primary sm:text-4xl text-center">
                        Réglement TAFRAOUT CAMP 2023
                        <span className="text-3xl font-light mt-4 text-slate-100 block sm:ml-0 underline-offset-1 border-primary">
                            Organisateur : KNR EVENTS. sarl
                        </span>
                        <span className="text-3xl font-bold text-slate-100 sm:block sm:ml-0">
                        </span>
                    </h1>
                        <div className='text-center mt-6'>
                            <a
                                href={reglement}
                                className={btnOutline}
                            >
                                Télécharger le réglement
                            </a>
                        </div>

                </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 border gap-10 border-gray-600 py-6 px-3 sm:px-6">
                        <div>

                            <p>
                                1/- Chaque participant doit remplir, totalement, le bulletin d’inscription de l’organisation, en s’acquittant de la totalité des droits d’inscription.
                                Chaque participant devra signer et prendre connaissance du règlement du TAFRAOUT CAMP édité par l’organisation.
                            </p>
                            <p>
                                2/- Dans le cadre de l’épidémie de la COVID 19, les participants devront respecter les règles de sécurité sanitaire édictées par le gouvernement Marocain.
                            </p>
                            <p>

                                3/- Le participant s’engage à respecter les horaires et le programme de l’organisation.
                            </p>
                            <p>

                                4/- Le vélo du Participant devra être en parfait état de roulage et le coureur à pieds possédant le matériel nécessaire pour le TRAIL  ( Espadrille, Casquette, Tenue.....)
                            </p>
                            <p>

                                5/- Sur le parcours, en cas de crevaison ou de panne mécanique, chaque participant devra être muni de son propre équipement de dépannage et être autonome pour réparer. Un contrôle obligatoire des VTT et de l’équipement de secours sera effectué lors du retrait du dossard.
                            </p>
                            <p>
                                6/- En cas de nécessité, tout au long des 2 jours de compétition, l’organisation met, un mécanicien-réparateur, à la disposition des participants.Chaque prestation sera payante ainsi que toute fourniture de matériel et pièces de rechange
                            </p>
                        </div>
                        <div>

                            <p>

                                7/- Tout au long du parcours, Chaque participant devra respecter l’itinéraire suivant le balisage  mis en place par l’organisation, ainsi que ses concurrents.
                                Sur l’itinéraire, chaque participant est tenu à respecter les consignes et le code de la route une fois sur la route nationale.
                            </p>
                            <p>

                                8/- Pour effectuer toute la distance de l’itinéraire de la compétition durant 2 jours, le participant s’engage à être en bonne santé physique et à avoir l’expérience nécessaire à la pratique du VTT et du TRAIL.
                            </p>
                            <p>

                                9/- Sur l’itinéraire, l’organisation de la gestion et de l’encadrement du TRAIL & BIKE ne s’effectue que dans la limite du parcours balisé pour  l’événement .
                                L’organisation est en charge de l’étape bivouac et des points de ravitaillements tout au long du parcours. (Km 5 + Km 10 + km 15 + Arrivée)
                            </p>
                            <p>

                                10/ -"Lors du contrôle des vélos, le participant devra présenter tout le matériel obligatoire (Casque, kit dépannage crevaison ,Gants, lunette, Sifflet ,2 Bouteilles d’eau, GSM opérationnel.)
                            </p>
                        </div>  
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export const Head = () => <Seo title="Camptafraout - réglement" />
export default Reglement